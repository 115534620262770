import { axiosBase as axios } from "./AxiosConfig";

export const GetAllProducts = async (token) => {
  try {
    const response = await axios.get("/products", {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetAllProductsByAuthId = async (token, authId, options) => {
  try {
    const response = await axios.post(
      `/allProductsByAuthId/${authId}`,
      options,
      { headers: { Authorization: `Bearer ${token}` }}
    );

    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const CreateProduct = async (token, product) => {
  try {
    const response = await axios.post("/products", product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const GetProductBySKU = async (token, SkuFulfillment) => {
  try {
    const response = await axios.get(`/products/${SkuFulfillment}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const UpdateProduct = async (token, SkuFulfillment, product) => {
  try {
    const response = await axios.put(`/products/${SkuFulfillment}`, product, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const DeleteProduct = async (token, SkuFulfillment) => {
  try {
    const response = await axios.delete(`/products/${SkuFulfillment}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const ActivateProduct = async (token, productId) => {
  try {
    const response = await axios.patch(`/products/activate/${productId}`, {},{
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    return response;
  } catch (error) {
    return error
  }
}

export const UpdateQTYProduct = async (token, data) => {
  try {
    const response = await axios.put(`/product/update`, data, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
}

export const CreateMassiveProducts = async (token, products) => {
  try {
    const response = await axios.post("/products/create/massive", { productsArray: products }, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response;
  } catch (error) {
    console.error(error);
    return error;
  }
};

export const createCustomFields = async (token, data) => {
  try {
    return await axios.post(
        `/products/customFields`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    )
  } catch (error) {
    return error
  }
}

export const getCustomFields = async (token, userId) => {
  try {
    return await axios.get(
        `/products/customFields/${userId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    )
  } catch (error) {
    return error
  }
}

export const actionCustomField = async (token, userId, customId, action) => {
  try {
    return await axios.delete(
        `/products/customFields/${userId}/${customId}/${action}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
    )
  } catch (error) {
    return error
  }
}

